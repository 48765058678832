.lgparsing-contel-bodydiv {
  width: 180px;
  height: 285px;
  background: #f5f8ff;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  p {
    font-size: 12px;
    color: #999999;
    &:nth-child(2) {
      font-weight: 600;
      font-size: 16px;
      color: #666666;
      line-height: 22px;
      margin-top: 5px;
    }
    &:nth-child(3) {
      margin-top: 9px;
      font-size: 12px;
      color: #999999;
    }
    &:nth-child(4) {
      font-size: 12px;
      color: #999999;
    }
  }
}

.lgparsing-contel-bodyactive {
  width: 180px;
  height: 285px;
  background: #e8ebf3;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

