.crumb-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bread-crumb {
    display: flex;
    span.interval {
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
    }
    .small-item {
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      &.active {
        font-weight: 500;
        font-size: 14px;
        color: #1d2129;
      }
    }
  }
}

