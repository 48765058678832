.finish-root {
  padding: 0 35px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & > div:first-child {
    display: flex;
    align-items: center;
    & > p {
      margin-left: 5px;
    }
  }
  & > h2 {
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #1d2129;
    margin: 0;
    margin-top: 12px;
  }
  & > p:last-child {
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
  }
}

