@font-face {
  font-family: "MyCustomFont";
  src: url("../../../assets/font/DOUYU/斗鱼追光体211203.ttf");
}

.DOUYU-text {
  font-family: "MyCustomFont";
  font-weight: 400;
  font-size: 12px !important;
  background: linear-gradient(180deg, #2d6cff 0%, #9358ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  /* Fallback for older browsers */
}

.menu-root {
  position: relative;
  height: calc(100% - 16px);

  .arco-tooltip-arrow {
    width: 0 !important;
    height: 0 !important;
  }

  .collapse-box {
    width: 30px;
    height: 30px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(79, 84, 225, 0.34);
    position: absolute;
    right: -15px;
    top: 57px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 999;
  }

  .menu-container {
    margin-top: 16px;
    height: 100%;

    .arco-menu-vertical .arco-menu-pop-header {
      overflow: unset;
    }

    .arco-menu-inner {
      height: calc(100vh - 66px);
      display: flex;
      flex-direction: column;
    }

    .arco-menu-inner::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }

    .arco-menu-inner::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    .arco-menu-inner::-webkit-scrollbar-thumb {
      background: #d8d8d8;
      border-radius: 30px;
    }

    .arco-menu {
      min-width: 107px;
      background-color: transparent;
    }

    .layout-subMenu {
      width: 100%;

      background-color: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 28px;
      line-height: normal;
      font-size: 14px !important;
      color: #333333;
      font-weight: 400;

      .arco-menu-item {
        padding: 0 !important;
      }
    }

    .menu-item {
      width: 100%;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      line-height: normal;
      font-size: 14px;
      color: #333333;
      font-weight: 400;
    }

    .arco-menu-vertical .arco-menu-pop-header,
    .arco-menu-vertical .arco-menu-inline-header {
      padding-right: 12px;
    }

    .arco-menu-selected {
      color: #3662ec !important;
    }

    .arco-menu-light .arco-menu-item.arco-menu-selected {
      background-color: transparent;
    }

    .arco-menu-light .arco-menu-pop-header:hover,
    .arco-menu-light .arco-menu-item:hover {
      background-color: transparent;
    }

    .arco-menu-item {
      overflow: unset;
    }

    .arco-menu-light .arco-menu-item {
      background-color: transparent;
    }

    .arco-menu-vertical .arco-menu-inline-header {
      margin-bottom: 0;
      overflow: unset;
    }

    .arco-menu-light .arco-menu-inline-header {
      background-color: transparent;
    }

    .arco-menu-item-inner {
      box-sizing: border-box;
      padding-left: 51px;
    }

    .arco-menu-vertical .arco-menu-inline-header .arco-menu-icon-suffix {
      right: 10px;
    }

    .menu-title {}

    .arco-menu-icon-suffix {
      font-size: 12px;
    }
  }

  .menu-flex {
    display: flex !important;
    align-items: center !important;
    justify-content: left !important;
    margin-bottom: 0 !important;
    margin-right: 12px;
    color: black;
  }

  .menu-container-collapse {
    margin-top: 16px;
    width: 107px;
    height: 100%;
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    overflow-y: auto;

    .menu-item-collapse {
      width: 100%;

      .menu-item-collapse-text {
        height: 51px;
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: column;

        margin-bottom: 0;
        line-height: normal;

        &>p {
          font-weight: 500;
          font-size: 14px;

          color: #333333;
        }
      }

      .menu-item-collapse-text:hover {
        color: #3662ec !important;
      }

      height: 51px;
      margin-bottom: 28px;
    }
  }
}

.menu-item-c-collapse {
  width: 220px;
  background: #ffffff;
  box-shadow: 0px 6px 16px 0px rgba(120, 120, 120, 0.28);
  border-radius: 8px;
  border: 1px solid #eeeeee;
  padding: 12px;
  padding-bottom: 0;
  box-sizing: border-box;

  .menu-item-c-collapse-item {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-family:
      PingFang HK,
      PingFang HK;
    font-weight: 400;
    font-size: 16px;
    color: #3d3d3d;
    margin-bottom: 12px;
    padding: 8px 19px;
    box-sizing: border-box;
    cursor: pointer;

    .menu-item-c-collapse-text {
      padding-left: 16px;
    }
  }

  .menu-item-c-collapse-item:hover {
    background: #eff2ff;
  }

  .menu-item-c-collapse-svg {
    width: 34px;
    height: 34px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.menu-icon {
  position: relative;
  display: inline-block;
  margin-bottom: 6px;

  .arco-image {
    height: 26px;
    line-height: normal;
  }
}

.menu-hot {
  position: absolute;
  top: -9px;
  right: -25px;
  width: 34px;
  height: 18px;
  background: linear-gradient(134deg, #ff9696 0%, #a768fc 100%);
  border-radius: 8px;
  border: 2px solid #ffffff;
  font-family: MyCustomFont;
  font-weight: 400;
  font-size: 8px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &>span {
    margin-top: 2px;
  }
}

.tasks-submenu {
  margin-top: auto !important;
}
