.user-root {
  width: 42px;
  height: 42px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  font-weight: 500;
  font-size: 16px;
}

.user-name {
  width: 190px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-text {
  width: 70px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-name {
  width: 190px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-box {
  width: 301px;
  background: #ffffff;
  box-shadow: 0px 6px 16px 0px rgba(120, 120, 120, 0.28);
  border-radius: 8px;
  border: 1px solid #eeeeee;
  padding: 21px 15px;
  padding-bottom: 15px;
  box-sizing: border-box;

  .user-header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 46px;

    &>div:nth-child(1) {
      width: 46px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: white;
      font-size: 16px;
      line-height: normal;
    }

    &>div:nth-child(2) {
      margin-left: 10px;

      &>p:first-child {
        font-family:
          PingFang SC,
          PingFang SC !important;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }

      &>p:last-child {
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        padding-top: 3px;
      }
    }

    &>div:nth-child(3) {
      margin-left: auto;
      cursor: pointer;
      height: 100%;

      &>img {}
    }
  }

  .term-of-validity {
    font-weight: 400;
    font-size: 10px;
    color: #D3C8BA;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;

    &>p {
      flex: none;
      text-align: center;
    }

    &>div:first-child {
      width: 100%;
      height: 2px;
      background: linear-gradient(270deg,
          rgba(96.1891557276249, 96.1891557276249, 100.17856925725937, 1),
          rgba(96.74660101532936, 96.74660101532936, 101.69642820954323, 0));
      position: relative;
      right: 11px;
      bottom: -1px;
    }

    &>div:last-child {
      width: 100%;
      height: 2px;
      background: linear-gradient(-270deg,
          #808084,
          rgba(96.74660101532936, 96.74660101532936, 101.69642820954323, 0));
      position: relative;
      left: 11px;
      bottom: -1px;
    }
  }

  .user-vip-box {
    width: 100%;
    background: linear-gradient(-270deg, #32323e 0%, #636375 100%);
    border-radius: 8px;
    padding: 14px;
    padding-bottom: 7px;
    box-sizing: border-box;
    margin-top: 15px;

    .legitimate-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &>div:first-child {
        font-weight: 500;
        font-size: 14px;
        color: #e4b883;
      }

      &>div:last-child {
        width: 64px;
        height: 22px;
        background: linear-gradient(-270deg, #f8e5c4 0%, #fcd79b 100%);
        border-radius: 20px 20px 20px 20px;
        font-weight: 500;
        font-size: 12px;
        color: #3c3c4a;
        padding: 3px 8px;
        line-height: 22px;
        cursor: pointer;
        text-align: center;
      }
    }

    .legitimate-info {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &>div {
        &>p:first-child {
          font-weight: 700;
          font-size: 18px;
          color: #ffffff;
        }

        &>p:last-child {
          font-weight: 400;
          font-size: 12px;
          color: #dfb37c;
        }
      }
    }
  }

  .user-tool {
    margin-top: 10px;

    .arco-menu-inner {
      padding: 0;
      border-radius: 8px !important;
      font-family:
        PingFang SC,
        PingFang SC !important;
      font-weight: 500;
      font-size: 14px;
      color: #3d3d3d;
    }

    .arco-menu-vertical .arco-menu-item {
      border-radius: 8px;
      display: flex;
      align-items: center;
    }

    .arco-menu-light .arco-menu-item:hover,
    .arco-menu-light .arco-menu-pop-header:hover {
      background: #eff2ff;
    }

    .arco-menu-vertical .arco-menu-inline-header,
    .arco-menu-vertical .arco-menu-item {
      //   padding: 0;
    }

    .my-icon {
      display: flex;
      align-items: center;
      margin-right: 12px;
    }

    .arco-menu-vertical .arco-menu-pop-header {
      display: flex;
      align-items: center;
    }
  }
}

.team-content {
  width: 179px;
  background: #ffffff;
  box-shadow: 0px 6px 16px 0px rgba(120, 120, 120, 0.3);
  border-radius: 8px;
  padding: 15px;
  padding-bottom: 10px;
  padding-right: 8px;

  &>div {
    .arco-menu-item {
      justify-content: space-between;
    }

    .team-logo {
      display: flex;
      align-items: center;
    }

    .team-name {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 12px;
      border: 1px solid #eeeeee;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;

      &>img {
        margin-top: 2.5px;
      }
    }

    .arco-menu-item {
      display: flex;
      align-items: center;
      height: 42px;
      border-radius: 8px;
      padding: 5px;
      padding-right: 17px;
      box-sizing: border-box;
    }

    .arco-menu-item:hover {
      background: #eff2ff;
    }
  }

  .mask-loding {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .switching-box {
      width: 260px;
      height: 115px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      font-family:
        PingFang SC,
        PingFang SC !important;
      font-weight: 500;
      font-size: 16px;
      color: #1d2129;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .team-status {
    width: 20px;
    font-weight: 400;
    font-size: 10px;
    color: #f53f3f;
  }
}

.user-input {
  font-family:
    PingFang SC,
    PingFang SC !important;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  width: 177px;
  border: none;
  line-height: normal;
}

.user-input:disabled {
  background-color: white;
}

// .arco-dropdown-menu-item {
//   display: flex !important;
//   align-items: center !important;
// }
.arco-popover-content {
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: white;
}

// .arco-trigger-arrow {
//   display: none;
// }
