.modal-footer {
  margin: 28px 0;
  text-align: right;
  padding: 0 25px;
  & > button {
    height: 32px;
    margin-left: 8px;
  }
}
.arco-modal-content {
  padding: 0;
}

