.material-upload-block-bg {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  background: #f5f8ff;
  border-radius: 4px;
  flex-direction: column; /* 让 img 和 p 垂直排列 */
  align-items: center; /* 水平居中 */
  justify-content: center; /* 垂直居中 */
  height: 100%; /* 根据需要调整高度 */
  text-align: center; /* 确保 p 内部内容居中 */
  img.add-img {
    width: 20px;
    height: 20px;
  }
  p {
    font-weight: 500;
    font-size: 12px;
    color: #666666;
  }
  img.tip-img {
    width: 10px;
    height: 10px;
  }
}

