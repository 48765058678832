.material-folder-container {
  height: 545px;
  width: 100%;
  .upload-material-folder-contaienr {
    max-height: 545px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
  }
  .upload-modal-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    flex-direction: column;
    img {
      width: 100px;
    }
    p {
      font-size: 14px;
      color: #666666;
      font-weight: 400;
    }
  }
  .file-upload-small-material-card-content p {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    width: 106px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}

