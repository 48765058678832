.form {
  flex: 1;
  margin-right: 20px;
}

.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 360px;
  height: 640px;
  background: #333;
  overflow: hidden;
  .video-controlsprogress-slide {
    position: absolute;
    left: 0;
    bottom: 40px;
    width: 94%;
    background: rgba(134, 144, 156, 0.54);
    border-radius: 8px 8px 8px 8px;
    height: 33px;
    margin: 0 12px;
  }
  .format-time {
    width: 100%;
    margin-top: 10px;
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    .format-tool {
      display: flex;
      align-items: center;
    }
  }
  .video-progress-slider {
    margin: 0 16px;
    height: 100%;
    display: flex;
    align-items: center;

    .arco-slider {
      width: 100% !important;
      // padding: 0 3%;
      // align-items: center;
      // line-height: 33px;
      height: 12px;
    }

    .arco-slider-road::before {
      height: 5px;
    }

    .arco-slider-button {
      z-index: 3;
      height: 14px;
      width: 14px;
    }

    .arco-slider-button::after {
      border: none;
    }

    .arco-slider-bar {
      height: 5px;
      background-color: rgb(156, 191, 255);
    }
  }
  .audio-control-box {
    height: 100px;
    padding-bottom: 5px;
    .arco-slider-wrapper {
      height: 100%;
      .arco-slider-road-vertical {
        min-height: auto;
      }
    }
  }
  .volume-control {
    display: flex;
    align-items: center;

    .arco-slider {
      height: 100px; // 滑块高度
      margin-left: 10px; // 滑块和按钮之间的间距
    }
  }
}
.play-btn {
  position: absolute;
}
.compose-preview {
  margin: 10px auto;
  padding: 10px;
  border: 1px solid #ccc;
  word-break: break-all;
}

/* 隐藏默认播放器控件 */
.Player_player_player__13kf6 .Player_player_player_tool_bar__2nLR3,
.ProgressBar_player_progressBar__13sfL {
  display: none !important;
}

