.person-upload-root {
  margin: 0 35px;
  .enterprise-form {
    margin-top: 8px !important;
  }
  .trigger {
    width: 158px;
    height: 106px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:hover {
      .trigger-hover {
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
      }
    }

    .trigger-hover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(88, 88, 88, 0.65);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      & > span {
        margin-left: 3px;
      }
      opacity: 0;
    }
  }
  .trigger-text {
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    text-align: center;
    margin-top: 14px;
  }

  .enterprise-form {
    margin-top: 22px;
  }
  .behind-box {
    .arco-form-message {
      padding-left: 35px;
    }
    .arco-form-item-symbol {
      opacity: 0;
    }
  }
  .upload-info {
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #aaaaaa;
    margin-left: 53px;
    & > p {
      height: 22px;
      line-height: 0px;
    }
  }
}

