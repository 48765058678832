.create-team-box {
  height: 176px;
  background: #f4f7ff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.team-moadl-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  & > h1 {
    margin: 0;
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #1d2129;
  }
}
.team-moadl-input {
  margin-top: 10px;
  .arco-input-inner-wrapper {
    background: #f4f7ff;
    border-radius: 2px;
    border: 1px solid #e2e2e2;
  }
  .arco-input-inner-wrapper-focus {
    border-color: rgb(22, 93, 255);
  }
  .arco-input-inner-wrapper-error {
    border-color: rgb(245, 63, 63);
  }
  .arco-input::placeholder {
    font-size: 14px;
  }
}

