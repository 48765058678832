.intelligent-root {
  height: 100%;

  // transition: width 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: #ffffff;
  border-radius: 18px 18px 0px 0px;
  border-radius: 12px;
  display: flex;
  flex: 1;

  .intelligent-box {
    display: flex;
    flex: 1;
    flex-direction: column;

    .intelligent-header {
      height: 56px;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 18px;
    }

    .intelligent-content {
      display: flex;
      height: 100%;

      .intelligent-left {
        width: calc(100% - 70px);
        padding: 18px;
        height: 100%;
        overflow: auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .original-box {
          white-space: pre-line;
          position: relative;
          margin-bottom: 12px;
          width: 100%;
          background: linear-gradient(135deg,
              rgba(22, 93, 255, 0.2) 0%,
              rgba(203, 152, 250, 0.2) 100%);
          padding: 0 10px;
          border-radius: 12px;
          box-sizing: border-box;

          .original-corner-mark {
            position: absolute;
            left: 0;
            top: 0;
            width: 52px;
            height: 20px;
            background: #165dff;
            border-radius: 12px 0px 12px 0px;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .original-text {
            margin-top: 27px;
            background: rgba(255, 255, 255, 0.62);
            border-radius: 8px;
            padding: 8px;
            font-weight: 400;
            font-size: 14px;
            color: #3d3d3d;
            line-height: 20px;
            box-sizing: border-box;
            margin-bottom: 10px;
          }
        }

        .typed-tool {
          display: flex;
          align-items: center;

          .typed-loading {
            width: 80px;
            height: 40px;
            line-height: 36px;
            background: #f3f7fe;
            border-radius: 12px 12px 12px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-weight: 500;
            font-size: 14px;
            color: #3d3d3d;
          }
        }

        .typed-reset-box {
          text-align: center;
          margin-top: 10px;

          .typed-reset {
            width: 132px;
            height: 38px;
            background: linear-gradient(135deg, #165dff 0%, #cb98fa 100%);
            border-radius: 8px 8px 8px 8px;
            border: none;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
          }
        }


      }

      .intelligent-right {
        width: 70px;
        border-left: 1px solid #eeeeee;
        padding: 17px;
        box-sizing: border-box;

        .intelligent-tab-box {
          text-align: center;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          margin-bottom: 20px;
          cursor: pointer;

          .intelligent-tab {
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.intelligent-msg {
  width: 100%;
  text-align: center;
  font-family:
    PingFang SC,
    PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #9e9e9e;
  margin-top: auto;
}
