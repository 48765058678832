.document-root {
  box-sizing: border-box;
  background: #f3f7fe;
  border-radius: 12px 12px 12px 12px;
  padding: 20px;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 14px;
  color: #3d3d3d;
  line-height: 20px;
  padding-bottom: 30px;
  position: relative;
  word-break: break-all;

  .document-tool {
    position: absolute;
    right: 20px;
    bottom: 7px;
    cursor: pointer;
  }


}
