.my-task-root {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  .task-middle-box {
    flex: 1;
    margin-left: 8px;
  }
  h2 {
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    width: 320px;
  }
  .my-task-type {
    display: block;
    width: 37px;
    height: 20px;
    border-radius: 4px 4px 4px 4px;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    margin-right: 8px;
  }

  .task-user-info {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 320px;
  }
  .type-color-p {
    background: #f4e8ff;
    color: #8703ff;
  }
  .type-color-g {
    background: #daf6e0;
    color: #2d8f45;
  }
  .task-image {
    position: relative;
    .unread-circle {
      background-color: red;
      width: 13px;
      height: 13px;
      position: absolute;
      z-index: 3;
      border-radius: 50%;
      border: 1px solid white;
      right: -6px;
      top: -6px;
    }
    .task-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(51, 51, 51, 0.64);
      left: 0;
      top: 0;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 10px;
      color: #ffffff;
      flex-direction: column;
      p {
        margin-top: 3px;
      }
      .circle-container {
        width: 32px;
        height: 32px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        border: 4px solid white;
      }
    }
  }
  .ellipsis-filename {
    display: inline-flex;
    max-width: 320px; /* 根据需要调整宽度 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filename-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* 允许名称部分在容器中占据剩余空间 */
    flex-shrink: 1;
  }

  .filename-extension {
    flex-shrink: 0;
  }
}
.arco-progress-circle-mask {
  stroke: white;
}
.arco-progress-circle-path {
  stroke: transparent;
}

