.project-list {
  height: 545px;
  position: relative;
  .project-list-title {
    font-weight: 500;
    font-size: 16px;
    color: #333333;
  }
  .spin-project {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
  }
}
.project-box {
  max-height: 450px;
  min-height: 450px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #EEEEEE;
  padding: 14px 13px;
  align-content: flex-start;
  .pro-item {
    cursor: pointer;
    width: 120px;
    height: 142px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 21px 16px 23px 17px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    text-align: center;
    .pro-img {
      margin: 0 auto;
    }
  }
  .pro-item:hover {
    background: #F8F8FF;
    border-radius: 6px 6px 6px 6px;
    color: #575CFF;
  }
}
.nav-clear {
  margin: 0 auto;
  text-align: center;
}

@keyframes loading {
  50% {
    height: 20%;
    opacity: 0.2;
    //filter: hue-rotate(360deg);
  }
}

