.upload-node-wrapper {
  width: 106px;
  height: 142px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;

  .upload-contaienr {
    width: 106px;
    height: 112px;
    border-radius: 4px 4px 4px 4px;
    background: #ffffff;
  }

  * {
    box-sizing: border-box;
  }

  .upload-error-wrapper,
  .parse-loading-wrapper {
    height: 112px;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    p {
      position: absolute;
      bottom: 20px;
      z-index: 3;
    }

    .cover-mask {
      position: absolute;
      width: 100%;
      height: 112px;
      background: rgba(51, 51, 51, 0.49);
      z-index: 2;
    }

    .cover-img-wrapper {
      width: 100%;
      height: 112px;
      position: absolute;
      z-index: 2;

      img {
        height: 100%;
        width: 100%;
        position: relative;
        object-fit: cover;
        z-index: 1;
      }

      position: absolute;
    }

    .upload-progress-wrapper {
      position: relative;
      width: 80%;
      z-index: 10;

      img {
        position: absolute;
        right: 0px;
        top: -3px;
        width: 10px;
      }

      .arco-progress-line-outer {
        height: 2px;
      }
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .parse-progress-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      animation: rotate 1.2s linear infinite;
      position: relative;
      z-index: 100;
    }

    .arco-progress-line-outer {
      height: 2px !important;
    }
  }

  // 未发送
  .wait-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background: #f5f8ff;
    padding: 7px;
    border-radius: 4px 4px 4px 4px;
    height: 112px;
  }

  .wait-msg {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 10px;
      color: #999999;
    }
  }

  .uploading-wrapper {
    border-radius: 4px 4px 4px 4px;
    background: #f5f8ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 112px !important;
    position: relative;

    .cover-mask {
      position: absolute;
      width: 100%;
      height: 112px;
      background: rgba(51, 51, 51, 0.49);
      z-index: 2;
    }

    .cover-img-wrapper {
      width: 100%;
      height: 112px;
      position: absolute;
      z-index: 2;

      img {
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 1;
        object-fit: cover;
      }

      position: absolute;
    }

    .uploading-progress-wrapper {
      margin: 0 auto;
      width: 80%;

      .opearte {
        position: relative;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: 10px;

        div {
          font-weight: 400;
          font-size: 10px;
          color: #ffffff;
        }
      }

      .arco-progress-line-outer {
        height: 2px !important;
      }
    }

    p {
      position: relative;
      z-index: 3;
      width: 100%;
      text-align: center;
      font-weight: 400;
      font-size: 10px;
      color: #ffffff;
    }
  }

  .img-wrapper {
    width: 100%;
    height: calc(100% - 30px);
    color: green;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .type-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .time-wrapper {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  // .upload-node-wrapper {
  //   color: red;
  // }

  .upload-node-wrapper,
  .uploading-wrapper {
    width: 100%;
    height: 100%;
  }

  .name {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* 关键属性，确保文本垂直方向限制为一行 */
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    height: 18px;
  }

  .upload-error-wrapper {
    width: 100%;
    height: 112px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    color: #333333;
    background: #f5f8ff;
  }
}

.upload-error-wrapper {
  .error-tip-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &>p {
    color: rgb(249, 130, 130);
  }
}

.upload-error-tip {
  .arco-popover-inner {
    padding: 4px 6px;
  }
}

.music-cover-picture {
  object-fit: contain;
}
