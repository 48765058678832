.show-up-load-success {
  width: 106px;
  height: 112px;
  box-sizing: border-box;
  position: relative;
  &:hover {
    .icon-close-btn {
      display: flex;
    }
  }
  .icon-close-btn {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 3px;
    top: 2px;
    width: 14px;
    height: 14px;
    z-index: 10;
    border-radius: 50%;
    background: #636363;
    cursor: pointer;
    * {
      font-size: 8px;
      color: white;
    }
  }
  .cover-img {
    width: 106px;
    height: 112px;
    overflow: hidden;
    border-radius: 8px 8px 8px 8px;
    background: #f5f8ff;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .type-tag-wrapper,
  .time-wrapper {
    position: absolute;
  }
  .type-tag-wrapper {
    right: 4px;
    top: 3px;
  }
  .time-wrapper {
    bottom: 10px;
    left: 10px;
    width: 35px;
    height: 14px;
    background: rgba(51, 51, 51, 0.44);
    border-radius: 2px 2px 2px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 10px;
    color: #ffffff;
  }
  p {
    text-align: center;
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
}

