.chickensoup-modal {
  margin-bottom: 20px;
  .chickensoup-header {
    height: 97px;
    margin-bottom: 15px;
    .chickensoup-title {
      justify-content: space-between;
      display: flex;
      height: 100%;
      background-image: url("../../../../../assets/workbenchimage/issue_icon.png");
      background-repeat: no-repeat;
      background-size: cover;
      & > div:last-child {
        cursor: pointer;
        margin: 5px 7px 0 auto;
      }
    }
  }
  .chickensoup-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h1 {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #333333;
      margin: 0;
    }
    & > p {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      margin-bottom: 12px;
    }
  }
}

