._root {
  position: relative;
}
.mask {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: absolute;
  background-color: #ffffff86;
}
.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}
.loading span {
  display: inline-block;
  animation: float 1.5s infinite;
  margin: 0 0.06rem;
  font-weight: bold;
}

// .loading span {
//     opacity: 0;
//     animation: float 1.2s infinite;
//     margin: 0 5px;
//     font-weight: bold;
//   }

.loading span {
  color: #653366;
}
.loading span:nth-child(2) {
  color: #ffcd36;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.loading span:nth-child(1) {
  animation-delay: 0s;
}
.loading span:nth-child(2) {
  animation-delay: 0.2s;
}
.loading span:nth-child(3) {
  animation-delay: 0.4s;
}
.loading span:nth-child(4) {
  animation-delay: 0.6s;
}
.loading span:nth-child(5) {
  animation-delay: 0.8s;
}
.loading span:nth-child(6) {
  animation-delay: 1s;
}

/* 
@keyframes float {  
    0%, 100% { opacity: 0; }  
    50% { opacity: 1; }  
  }  
    
  .loading span:nth-child(1) { animation-delay: 0s; }  
  .loading span:nth-child(2) { animation-delay: 0.2s; }  
  .loading span:nth-child(3) { animation-delay: 0.4s; }  
  .loading span:nth-child(4) { animation-delay: 0.6s; }  
  .loading span:nth-child(5) { animation-delay: 0.8s; }  
  .loading span:nth-child(6) { animation-delay: 1s; } */

