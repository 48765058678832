.popover-root {
  .arco-badge-number {
    top: 0px;
    right: -8px;
    height: 18px;
    line-height: 18px;
  }
  height: 22px;
  .my-task-root {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    .circle-box {
      position: absolute;
      width: 20px;
      height: 20px;
      background: #eb3b3b;
      border: 2px solid #ffffff;
      font-weight: 500;
      font-size: 12px;
      color: #ffffff;
      border-radius: 50%;
      right: -10px;
      top: -11px;
      line-height: 20px;
      text-align: center;
    }
  }
  .task-tip {
    background: white;
  }
  .arco-badge {
    height: 22px;
  }
}
.arco-trigger {
  max-width: 9999px !important;
}
.my-task-box {
  width: 520px;
  box-sizing: border-box;
  position: relative;
  background: #ffffff;
  padding: 28px;
  padding-bottom: 0;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  min-height: 561px;

  .task-empty-box {
    width: 100%;
    padding-top: 119px;
    text-align: center;
    & > p {
      padding-right: 12px;
      margin-top: 5px;
    }
  }
  h1 {
    margin: 0;
    font-size: 18px;
    color: #333333;
    padding-bottom: 8px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 5px;
  }
  .my-task-footer {
    height: 50px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
  }
  .my-task-content {
    padding-top: 5px;
    height: 471px;
    overflow-y: auto !important;
    overflow-x: hidden;
    box-sizing: border-box;
  }
}
.arco-tooltip-content {
  // padding: 0px;
  border-radius: 4px;
}

