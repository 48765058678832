.exclusive-moadl-title {
  display: flex;
  align-items: center;
  height: 75px;
  padding-left: 38px;
  box-sizing: border-box;

  &>p {
    margin-left: 8px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
  }
}

.exclusive-root {
  padding: 0 38px;
  padding-bottom: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .exclusive-header {
    display: flex;
    align-items: center;
    margin-bottom: 21px;

    &>p {
      width: 100%;
      height: 1px;
      background: linear-gradient(-270deg,
          rgba(51.00000075995922, 51.00000075995922, 51.00000075995922, 1),
          rgba(51.00000075995922, 51.00000075995922, 51.00000075995922, 0));
      position: relative;
      left: 8px;
      bottom: -1px;
    }
  }

  .exclusive-box {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    img {
      margin-right: 10px;
    }

    .box-right {
      &>p:first-child {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #1D2129;
      }

      &>p:last-child {
        font-size: 12px;
        color: #1D2129;
      }
    }
  }

  &>div {
    width: 280px;
    height: 240px;
    background: #FCFDFF;
    border-radius: 2px;
    border: 1px solid #EAF0FF;
    padding: 13px 18px;
    box-sizing: border-box;
  }

  &>div:last-child {
    width: 320px;
    padding: 13px;
  }

  .meal-form {
    .arco-form-item {
      margin-bottom: 31px;

      .arco-form-label-item {
        font-family:
          PingFang SC,
          PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #1d2129;

        &>label {
          white-space: nowrap;
        }
      }

      input {
        height: 38px;
        border-radius: 4px;
      }
    }

    .arco-form-item-status-error .arco-input:not(.arco-input-disabled) {
      background-color: white;
      border-color: red !important;
    }

    .arco-form-message {
      position: absolute;
      // top: -10px;
      // left: 12px;
      // background-color: white;
      // opacity: 1 !important;
      padding-left: 10px;
    }
  }


}

.exfooter-root {
  padding: 0 38px;
  padding-top: 15px;
  margin-bottom: 25px;

  .get-btn {
    border-radius: 4px;
    width: 100%;
    height: 38px;
  }
}
