.ssml-tag-value-select.time {
  .ssml-tag-value-select__inputbox {
    position: absolute;
    top: 20px;
    left: -20px;
    &::before {
      position: absolute;
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      transform: rotate(45deg);
      border-style: solid;
      border-width: 1px;
      border-color: #dddddd transparent transparent #dddddd;
      left: 20px;
      top: -2px;
      z-index: 1;
      background: white;
    }
    .ssml-tag-value-select__inputbox__inner {
      z-index: 10;
      padding: 0 8px;
      color: #979797;
      border-radius: 4px;
      border: 1px solid #dddddd;
      background: #ffffff;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    input {
      min-width: initial;
      width: 40px;
      height: 32px;
      border: 0 none;
      text-align: right;
      color: #222;
    }
    .seconds {
      margin-top: 1px;
    }
  }
}
.locale-cn,
.locale-tw {
  .ssml-tag-value-select.time .ssml-tag-value-select__inputbox input {
    width: 60px;
  }
}

