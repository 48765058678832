.load_11 {
  width: 60px;
  height: 30px;
  display: inline-block;
  text-align: center;
  font-size: 10px;
}
.load_11 > div {
  background-color: #437aff;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
  margin-right: 3px;
}
.load_11 .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
  background-color: #5a73ff;
}
.load_11 .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background-color: #706bff;
}
.load_11 .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
  background-color: #8c63ff;
}
.load_11 .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background-color: #a25bff;
  margin-right: 0 !important;
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

