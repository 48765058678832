.grid-box {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  transition: justify-content 1s ease;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  /* 禁止项目自动拉伸 */
  flex: 0 0 auto;

  &.placeholder {
    visibility: hidden;
    /* 隐藏占位元素，但占据空间 */
  }
}
