._video-hoc-wrapper {
  position: relative;
  flex-shrink: 0;

  ._video-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 25px;
    height: 25px;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    z-index: 10;
    cursor: pointer;
  }

  ._video-wrapper {
    margin: 0 !important;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    bottom: 0;
    right: 0;
  }

  &:hover {
    ._video-play-button {
      display: flex;
    }
  }
}
