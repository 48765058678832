.local {
  position: relative;
}
.upload-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.upload-file-modal {
  .pagination-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    .arco-pagination-size-default {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .arco-pagination-list {
        margin-left: auto;
      }
    }
  }
  .upload-file-modal-content {
    padding: 19px 23px;
    box-sizing: border-box;
  }
  .modal-header {
    height: 57px;
    background: linear-gradient(
      135deg,
      rgba(65, 122, 255, 0.3) 0%,
      rgba(171, 80, 255, 0.084) 100%
    );
    .moadl-title {
      justify-content: space-between;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 22px;
      & > div:first-child {
        display: flex;
        align-items: center;
        & > span {
          font-weight: 500;
          font-size: 16px;
          color: #1d2129;
          margin-left: 17px;
        }
      }
      & > div:last-child {
        cursor: pointer;
      }
    }
  }
  .arco-modal-content {
    padding: 0 !important;
    .file-upload-modal-content {
      display: flex;
      margin-top: 12px;
    }
    .material-file-modal-contaienr {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 545px;
      border: 1px solid #eee;
      border-radius: 8px;
      padding: 12px 15px;
      position: relative;
      .clickSpin {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .opeator-bar {
        display: flex;
        justify-content: space-between;
      }
      .material-wrapper {
        height: 100%;
        .arco-spin-children {
          height: 100%;
          .material-container {
            height: 90%;
            overflow-y: scroll;
          }
        }
      }
    }
  }
  .arco-modal-footer {
    padding-top: 0px;
  }
}

