.header_root {
  height: 70px;
  padding-right: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo-box {
    display: flex;
    align-items: center;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 4px;
      cursor: pointer;
    }
  }
  .tool-box {
    display: flex;
    align-items: center;
    & > div {
      margin-left: 37px;
    }
  }
  .head-text {
    line-height: 33px;
    height: 36px;
    margin-left: 10px;
    font-weight: 500;
    font-size: 22px;
    color: #333333;
    font-family:
      PingFang SC,
      PingFang SC;
  }
}

