.user-create-team-moadl {
  padding: 25px;
  padding-bottom: 0;
  box-sizing: border-box;
  .team-moadl-msg {
    margin-top: 20px;
    & > h1 {
      margin: 0;
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #1d2129;
    }
    & > p {
      font-weight: 400;
      font-size: 14px;
      margin-top: 6px;
      color: #666666;
    }
  }
  .team-moadl-select {
    display: flex;
    align-items: center;
    margin-top: 18px;
    & > div:first-child {
      font-family:
        PingFang SC,
        PingFang SC;
      width: 56px;
      font-weight: 400;
      font-size: 14px;
      color: #1d2129;
      margin-right: 11px;
    }
    .vlong-select-box {
      width: 220px;
    }
  }
  .not-in-team {
    // padding-left: 67px;
    margin-top: 7px;
    height: 20px;
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #165dff;
    cursor: pointer;
  }
}
.option-text {
  display: flex;
  align-items: center;
}
.block-up {
  color: red;
  margin-left: auto;
}

