.collapse-root {
  .collapse-btn {
    display: flex;
    align-items: center;
    & > div:first-child {
      width: 100%;
      height: 0px;
      border-bottom: 1px solid #eeeeee;
    }
    & > div:nth-child(2) {
      width: 140px;
      margin: 0 20px;
      text-align: center;
      cursor: pointer;
    }
    & > div:last-child {
      width: 100%;
      height: 0px;
      border-bottom: 1px solid #eeeeee;
    }
    font-size: 14px;
  }
}

.collapse-content {
  overflow: hidden;
  transition: max-height 0.3s ease;
  background-color: white;
  // padding: 0 15px;
}

