.assistant-root {
  height: 100%;
  background: #ffffff;
  border-radius: 12px;
  position: relative;
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;

  .assistant-header {
    height: 56px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
  }

  .assistant-box {
    flex: 1;
    overflow: auto;

    .assistant-content {
      width: 100%;
      padding: 22px;
      padding-bottom: 10px;
      box-sizing: border-box;
      position: relative;
      overflow: auto;
      display: flex;
      flex-direction: column;

      .assistant-title {
        width: 100%;
        display: flex;
        align-items: center;
        height: 70px;
        margin-bottom: 15px;

        background: linear-gradient(135deg,
            rgba(22, 93, 255, 0.15) 0%,
            rgba(203, 152, 250, 0.15) 100%);
        border-radius: 12px;

        .AI-icon {
          margin: -4px 11px 0 20px;
        }

        h2 {
          font-weight: bold;
          font-size: 16px;
          color: #3d3d3d;
          margin-bottom: 5px;
          line-height: 20px;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
        }
      }

      .examples-box {
        .examples-list {
          display: inline-block;
          margin-top: 12px;
          background: #f5f5f6;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #d9d9d9;
          font-weight: 400;
          font-size: 14px;
          color: #777777;
          padding: 10px 15px;
          cursor: pointer;
          word-break: break-all;
        }
      }

      .ai-loading-box {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .ai-loading {
          width: 80px;
          height: 40px;
          background: #f3f7fe;
          justify-content: center;
          display: flex;
          align-items: center;
          border-radius: 12px 12px 12px 12px;
          text-align: center;
          font-weight: 500;
          font-size: 14px;
          color: #3d3d3d;
        }
      }

      .my-info {
        width: 100%;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: right;

        &>div {
          display: inline-block;
          padding: 8px 17px;
          background: linear-gradient(44deg, #3b76ff 0%, #9780ff 100%);
          border-radius: 12px 12px 0px 12px;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          box-sizing: border-box;
        }
      }

      .ai-info {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        color: #3d3d3d;

        &>div:first-child {
          display: inline-block;
          background: #f6f8f9;
          border-radius: 12px 12px 12px 0px;
          padding: 13px 24px;
          box-sizing: border-box;
          position: relative;

          &>h1 {
            font-weight: bold;
            font-size: 16px;
            color: #333333;
            margin: 0;
            margin-bottom: 13px;
            line-height: 20px;
          }

          &>p {
            font-weight: 500;
            font-size: 14px;
            color: #3d3d3d;
            line-height: 20px;
          }

          .timer-info {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            bottom: -45px;
            left: 0;
            justify-content: space-between;
            padding: 10px 0;

            &>span {
              font-weight: 400;
              font-size: 14px;
              color: #999999;
            }

            .ai-copy {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .assistant-input-box {
    // position: absolute;
    // bottom: 20px;
    // width: calc(100% - 40px);
    transition: width 0.3s ease;
    padding: 0 20px;
    background-color: white;
    margin-top: auto;

    // margin-bottom: 20px;
    .chat-input {
      height: 44px;
      background: #ffffff;
      border-radius: 50px;
      margin-top: 10px;

      border: 1px solid transparent;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, white, white),
        linear-gradient(96deg,
          rgba(22.000000588595867, 93.00000205636024, 255, 1),
          rgba(138.00000697374344, 56.000000461936, 245.00000059604645, 1));

      .arco-input-inner-wrapper:focus {
        border-color: transparent !important;
      }
    }

    .new-chat {
      width: 100px;
      height: 38px;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #dddddd;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      background-color: white;
      margin-top: 10px;
    }

    .arco-input-inner-wrapper {
      padding: 0;
      background: #ffffff;
      border-radius: 50px;
    }

    .arco-input {
      padding-right: 0;
      border: none;
      padding: 0;
      height: 38px;
      padding-left: 15px;
    }

    .arco-input-inner-wrapper:focus,
    .arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
      border-color: transparent;
    }

    .arco-input-inner-wrapper {
      border-color: transparent;
    }

    .arco-btn-secondary:not(.arco-btn-disabled) {
      background-color: transparent;
      border-color: #dddddd;
      color: black;
    }
  }
}

.assistant-content::-webkit-scrollbar {
  width: 5px;
}

.assistant-content::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

.assistant-content::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 30px;
}
