// .video-upload-root {
//   .arco-upload-list-item {
//     margin-top: 0 !important;
//   }
//   .arco-upload-list {
//     & > div {
//       margin-top: 0 !important;
//     }
//   }
//   .arco-upload-trigger-picture {
//     background: #fafafa;
//     border: 1px solid #eeeeee;
//     border-radius: 8px;
//     font-size: 14px;
//     color: #666666;
//   }
//   .render-List {
//     width: 80px;
//     height: 80px;
//     margin-right: 12px;
//     margin-bottom: 12px;
//     position: relative;
//     .render-close-icon {
//       position: absolute;
//       right: -4px;
//       top: -12px;
//       z-index: 1;
//       cursor: pointer;
//       display: none;
//     }
//     .render-play {
//       position: absolute;
//       left: 50%;
//       top: 50%;
//       transform: translateX(-50%) translateY(-50%);
//       z-index: 1;
//     }
//   }
//   .render-List:hover {
//     & .render-close-icon {
//       display: block;
//     }
//   }
//   .render-img {
//     cursor: zoom-in;
//   }
// }
.video-upload-root {
  display: flex;
  flex-wrap: wrap;
  .render-List {
    margin-bottom: 12px;
    width: 80px;
    height: 80px;
    margin-right: 12px;
    position: relative;
    .render-close-icon {
      width: 12px;
      position: absolute;
      right: -4px;
      top: -12px;
      z-index: 1;
      cursor: pointer;
      display: none;
      .arco-image-img {
        height: 12px;
      }
    }
    .render-play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
      cursor: zoom-in;
    }
  }
  .arco-upload-trigger-picture {
    width: 78px;
    min-width: 78px;
    height: 78px;
    background: #fafafa;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    position: relative;
    margin-right: 12px;
    .video-upload {
      width: 80px;
      cursor: pointer;

      height: 80px;
      opacity: 0;
      left: 0;
      top: 0;
      position: absolute;
      z-index: 1;
    }
  }
  .arco-image-img {
    height: 80px;
  }
}
.render-List:hover {
  & .render-close-icon {
    display: block;
  }
}
.render-img {
  border-radius: 8px;
}
.render-play {
  .arco-image-img {
    height: 16px !important;
  }
}

.last-box {
  position: relative;
}
.last-hidden {
  position: absolute;
  background: rgba(51, 51, 51, 0.52);
  width: 80px;
  height: 80px;
  left: 0;
  top: 0;
  z-index: 2;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
}

