.hot-card {
  display: flex;
  flex-direction: column;
  width: 292px;
  height: 375px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px #e4eaf3;
  border-radius: 16px;
  border: 1px solid #eeeeee;
  margin-bottom: 18px;
  box-sizing: border-box;
  h1 {
    height: 55px !important;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    margin-top: 15px;
    padding: 0 18px;
    color: #333333;
    position: sticky;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .lable-box {
    display: flex;
    padding: 0 18px;
    margin: 12px 0;
    .card-lable {
      height: 24px;
      padding: 0 12px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      margin-right: 8px;
    }
  }
  .card-top {
    margin-left: 20px;
    height: 479px;
    overflow: auto;
    .card-content {
      height: 100%;

      & > h2 {
        font-weight: 600;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        margin-top: 10px;
        width: 264px;
      }
      & > h2:first-child {
        margin-top: 0;
      }
      & > h3 {
        margin-top: 4px;
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        line-height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 264px;
      }
      & > p {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        width: 264px;
      }
    }
  }
  .card-bottom {
    padding: 0 18px;
    height: 80px;

    .card-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 55px;
      .video-count {
        display: flex;
        align-items: center;
        & > span {
          margin-left: 7px;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          padding-top: 3px;
        }
      }
      .card-btn {
        .arco-btn {
          line-height: normal;
        }
        display: flex;
        align-items: center;
        .card-copy {
          height: 38px;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #165dff;
          background-color: transparent;
          font-weight: 400;
          font-size: 14px;
          color: #165dff;
          margin-right: 12px;
          display: flex;
          align-items: center;
        }
        .card-filmDditing {
          height: 28px;
          background: linear-gradient(135deg, #165dff 0%, #cb98fa 100%);
          border-radius: 2px 2px 2px 2px;
          border: none;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          display: flex;
          align-items: center;
        }
        .arco-btn-size-default {
          padding: 0 10px;
          font-size: 12px;
        }
      }
    }
  }
}
.underline-text {
  line-height: 1.5;
  background-image: linear-gradient(
    transparent,
    transparent 95%,
    #98b7fd9e 95%,
    #98b7fd9e 100%
  );
  background-size: 100% 300px; /* 下划线的厚度 */
  background-repeat: repeat-x;
  background-position: 0 98%; /* 定位到下方 */
}

.card-top::-webkit-scrollbar {
  width: 5px;
}

.card-top::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

.card-top::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 30px;
}
.hover-card::-webkit-scrollbar {
  width: 0;
}

