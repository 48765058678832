.close-wrapper {
  width: 11px;
  height: 11px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: black;
  cursor: pointer;
}

