.attestation-root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .msg-box {
    width: 665px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 58px;
    background: #f7f7f7;
    border-radius: 2px 2px 2px 2px;
    padding: 13px 20px;
    box-sizing: border-box;
    p {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      margin-left: 13px;
    }
  }
  .attestation-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    & > div {
      width: 181px;
      height: 91px;
      border: 1px solid transparent;
      border-radius: 4px;
      &:first-child {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          & > p {
            margin-left: 12px;
            font-family:
              PingFang SC,
              PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
          }
        }
        .recommend {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      &:last-child {
        cursor: pointer;
        margin-left: 109px;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          & > p {
            margin-left: 12px;
            font-family:
              PingFang SC,
              PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #165dff;
          }
        }
      }
    }
  }
}
.attestation-title {
  font-family:
    PingFang SC,
    PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin: 0 35px;
}
.active-card {
  border: 1px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, white, white),
    linear-gradient(
      131deg,
      rgba(22.000000588595867, 93.00000205636024, 255, 1),
      rgba(202.00000315904617, 152.0000061392784, 250.00000029802322, 1)
    );
  border-radius: 4px;
}

