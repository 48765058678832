.checkbox-wrapper-hoc {
  position: relative;
  border: 1px solid transparent;
  .checkbox-item {
    position: absolute;
    top: 2px;
    left: 1px;
    display: none;
    z-index: 100;
  }

  &.active {
    border: 1px solid rgba(22, 93, 255);
    > * {
      border: none !important;
    }
  }

  &:hover {
    .checkbox-item {
      display: inline-block;
    }
  }
}

