.file-upload-folder-img-wrapper {
  width: 106px;
  height: 112px;
  //background: #f5f8ff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  img {
    width: 58px;
    height: 58px;
  }
}

.file-upload-folder-folder-card-wrapper {
  .file-name {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    text-align: center;
    width: 106px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}

