.user-create-team-moadl {
  .arco-radio-button:hover {
    background-color: transparent;
  }
  position: relative;
  padding-top: 0;
  .radio-tool {
    position: sticky;
    padding-top: 10px;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: white;
    & > div:first-child {
      display: flex;
      align-content: center;
      justify-content: space-between;
    }
  }

  .script-tool {
    display: flex;
    align-content: center;
  }
  .arco-radio-button {
    line-height: 32px;
  }
  .arco-select-view {
    height: 38px !important;
    line-height: 38px !important;
  }
  .hybrid-select {
    margin-left: 8px;
    display: flex;
    width: 200px;
    align-items: center;
    border-radius: 2px;
    height: 36px;
    border: 1px solid #dddddd;
    .arco-select {
      height: 26px !important;
      line-height: 26px;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .arco-select-size-large.arco-select-single .arco-select-view {
      height: 26px !important;
      line-height: 26px !important;
      padding-right: 19px;
    }
    .arco-select .arco-select-view {
      border: none;
    }
    .sc-middle {
      border-right: 1px solid #d8d8d8;
      width: 1px;
      height: 26px;
    }
    .sc-wrapper {
      height: 16px;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
      line-height: normal;
    }
    .arco-input-inner-wrapper {
      border: none;
    }
  }

  .hot-card-root {
    margin-top: 2px;
  }
  .custom-script-import-empty {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    img {
      width: 60px;
    }
    p {
      margin-top: 10px;
      font-size: 14px;
      color: #333333;
      font-weight: 400;
      text-align: center;
    }
  }

  .row-tag {
    width: 100%;
    margin-left: 0;
    margin-top: 14px;
    min-height: 40px;
    display: flex;
    align-items: center;
    // margin-bottom: 16px;
    flex-wrap: wrap;
    & > span:first-child {
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      margin-right: 40px;
    }
    .tag-button {
      min-width: 58px;
      padding: 0 15px;
      height: 40px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #eeeeee;
      margin-right: 14px;
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
    }
    .active-tag-button {
      min-width: 58px;
      padding: 0 15px;
      height: 40px;
      border: 1px solid transparent;
      border-radius: 8px;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, white, white),
        linear-gradient(
          131deg,
          rgba(22.000000588595867, 93.00000205636024, 255, 1),
          rgba(202.00000315904617, 152.0000061392784, 250.00000029802322, 1)
        );
      margin-right: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      color: #165dff;
    }
  }
}

