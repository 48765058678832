.file-upload-small-material-card {
  display: inline-block;
  border-radius: 8px;
  text-align: center;
  height: 112px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  .tag-wrapper {
    position: absolute;
    top: 3px;
    right: 4px;
  }
  .import-tag-wrapper {
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-radius: 8px 0px 0px 0px;
    overflow: hidden;
  }
  .file-upload-small-material-card-image {
    width: 106px;
    height: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
}

