.ssml-tag-editor {
  ul {
    list-style: disc;
    margin: 0;
    padding-left: 1rem;
  }

  .tiptap p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    pointer-events: none;
    font-weight: 400;
    font-size: 14px;
    color: #aaaaaa;
  }

  // & ::selection {
  //   background: transparent; /* 使默认选择背景透明 */
  //   color: inherit; /* 确保文本颜色不变 */
  // }

  font-weight: normal;

  .mask-placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #aaaaaa;
    position: absolute;
    background-color: white;
    z-index: 1;
    left: 0;
    top: 0;
    width: calc(100% - 28px);
    height: calc(100% - 30px);
    padding: 15px 14px;
  }

  .textarea-word-limit {
    padding-left: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
  }


  .bottom-info {
    font-size: 12px;
    color: rgb(134, 144, 156);
    -webkit-user-select: none;
    user-select: none;
    font-weight: bold;
    z-index: 2;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 6px;
    right: 10px;
  }

  .estimated-duration {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: #EDF4FF;
    border-radius: 4px 4px 4px 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #165DFF;
  }
}

.flex--start {
  display: flex;
  justify-content: flex-start;
}

.flex--center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex--end--center {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex--start--center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex--between--center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex--between--start {
  display: flex;
  justify-content: space-between;
}

.flex--1 {
  flex: 1;
  min-width: 0;
}

.ssml-tag-editor {
  font-size: 14px;

  .ProseMirror {
    outline: none;
  }

  line-height: 20px;

  .attrs-mark {
    user-select: none !important;
  }

  .react-node-ssml-tag {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;

    .ssml-tag-value-select {
      position: relative;
      margin: 0 2px;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      color: #fff;
      cursor: pointer;
    }

    .ssml-tag-value-select__value {
      padding: 2px 4px;
      background-color: #1e6ee6;
      border-radius: 2px;
    }

    .ssml-tag-value-select__options {
      position: absolute;
      top: calc(100% + 2px);
      left: 0;
      z-index: 1;
      min-width: 96px;
      padding: 8px 0;
      white-space: nowrap;
      background: #fff;
      border: 1px solid #eee;
      border-radius: 4px;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);

      .ssml-tag-value-select__options__item {
        padding: 8px 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #222;
        cursor: pointer;

        &:hover {
          background-color: #f5f5f5;
        }

        &.active {
          background-color: #f5f5f5;
        }
      }
    }
  }

  .node-say-as,
  .node-break,
  .node-phoneme,
  .node-sub {
    display: inline-block;
    cursor: default;

    .attrs-mark {
      cursor: pointer;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
}

/* index.less */
.sensitive-word {
  background-color: rgba(255, 0, 0, 0.2) !important;
  /* 淡红色背景 */
  /* 深红色文字 */
}
