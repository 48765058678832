.enterprise-upload-root {
  margin: 0 35px;
  .enterprise-form {
    margin-top: 8px !important;
  }
  .upload-title {
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #1d2129;
    margin-bottom: 8px;
    margin-top: 5px;
  }
  .trigger {
    border: 1px dashed #bfbfbf;
    border-radius: 2px;
    width: 181px;
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #bfbfbf;
    & > span {
      margin-top: 5px;
    }
  }
  .upload-box {
    display: flex;
    align-items: center;
    .upload-info {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #aaaaaa;
      & > p {
        height: 22px;
        line-height: 22px;
      }
    }
  }
  .enterprise-form {
    margin-top: 22px;
  }
  .arco-spin {
    width: 100%;
  }
}

