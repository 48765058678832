.header-container-root {
  background-color: white;
  padding: 15px;
  border-radius: 12px;
  box-sizing: border-box;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 99px;
  // max-height: 109px;
  background: linear-gradient(
    135deg,
    #3482ff 0%,
    rgba(171, 80, 255, 0.56) 100%
  );
  border-radius: 8px 8px 8px 8px;
  padding: 26px 33px;
  box-sizing: border-box;
  & > div:first-child {
    display: flex;
  }
  h1 {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 26px;
    color: #ffffff;
    line-height: 22px;
    color: #ffffff;
    margin-right: 8px;
  }
  .sub-name {
    margin-top: 13px;
    font-size: 16px;
    color: #e8f2ff;
    line-height: 22px;
  }
  .header-title {
    display: flex;
    align-items: center;
  }
}

