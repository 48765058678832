.react-node-ssml-tag.break {
    .ssml-tag-value-select__value {
        background-color: #3F2088;
    }
}
.tiptap p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
