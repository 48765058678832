.showUploadNoda-wrapper {
  width: 106px;
  p {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}

