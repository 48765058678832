.smart-search-root {
  width: 488px;
  height: 50px;
  position: fixed;
  // left: -50%;
  // bottom: -50px;
  display: flex;
  align-items: center;
  background: linear-gradient(131deg, #1371ff 0%, #be76ff 100%);
  box-shadow: 0px 2px 10px 0px rgba(130, 150, 196, 0.3);
  border-radius: 50px;
  z-index: 3;
  padding: 6px;
  box-sizing: border-box;
  overflow: hidden;

  .smart-inpout-box {
    width: 224px;

    .arco-input-inner-wrapper {
      padding: 0;
      background: #ffffff;
      border-radius: 50px 50px 50px 50px;
    }

    .arco-input {
      padding-right: 0;
      border: none;
      padding: 0;

      height: 38px;
      padding-left: 15px;
    }

    .arco-input-inner-wrapper:focus,
    .arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
      border-color: transparent;
    }
  }

  .smart-tool-box {
    width: 0;
    transform: translateX(280px);
    transition:
      transform 0.3s ease-in,
      width 0.3s ease;
    display: flex;
    align-items: center;
  }

  .smart-tool-box-h {
    width: 0;
    transform: translateX(280px);
    transition:
      transform 0.2s ease-out,
      width 0.3s ease;
    display: flex;
    align-items: center;
  }

  .smart-tool-btn {
    display: flex;
    align-items: center;
    height: 30px;
    animation: name duration timing-function delay iteration-count direction fill-mode;
    border-radius: 40px;
    border: none;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff !important;
    margin-right: 6px;

    .arco-image {
      margin-right: 5px;
      margin-bottom: 2px;
    }

    .arco-btn-size-default {
      padding: 0 10px;
    }
  }
}
